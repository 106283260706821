/* storybook-check-ignore */
import { useReducer } from 'react';

import { Button, ButtonProps, Flex, Icon } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import Favorite from '@opendoor/bricks/core/Icon/SpritesheetIcons/Favorite';
import FavoriteFilled from '@opendoor/bricks/core/Icon/SpritesheetIcons/FavoriteFilled';

import { OdProtosBuyerV2Data_ReactionSource } from '__generated__/athena';

import {
  useWatchlistContext,
  WatchlistContext,
} from 'components/exclusives/contexts/WatchlistContextProvider';

import { Listing } from 'declarations/exclusives/listing';

import { getListingStreet } from '../../helpers/exclusives/listing';
import useExclusiveAnalytics from './hooks/useExclusiveAnalytics';
import useWatchlist from './hooks/useWatchlist';
import WatchlistModal from './WatchlistModal';

type FavoriteButton = Omit<ButtonProps, 'children' | 'onClick' | 'aria-label' | 'analyticsName'> & {
  accountEmail: string | undefined;
  addAnalyticsLabel?: string;
  analyticsAdditionalContextualData?: Record<string, unknown>;
  buttonPropsOnWatchlist?: Omit<ButtonProps, 'onClick' | 'aria-label' | 'analyticsName'>;
  children?: ({ isOnWatchlist }: { isOnWatchlist: boolean }) => JSX.Element;
  listing: Listing;
  iconSize?: number;
  isListingOnWatchlist?: boolean;
  onRemovedFromWatchlist?: () => void;
  onAddedToWatchlist?: () => void;
  removeAnalyticsLabel?: string;
  source: 'gallery' | 'pdp' | 'saved-homes';
};

export default function FavoriteButton({ onAddedToWatchlist, ...restOfProps }: FavoriteButton) {
  const { trackExclusivesEvent } = useExclusiveAnalytics();

  let source: OdProtosBuyerV2Data_ReactionSource | undefined;
  if (restOfProps.source === 'gallery') {
    source = 'REACTION_SOURCE_GALLERY';
  } else if (restOfProps.source === 'pdp') {
    source = 'REACTION_SOURCE_WEB_PDP_FAVORITE';
  } else if (restOfProps.source === 'saved-homes') {
    source = 'REACTION_SOURCE_UNKNOWN';
  }

  const isListingOnWatchlist = useWatchlist(restOfProps.listing, {
    onRemovedFromWatchlist: restOfProps.onRemovedFromWatchlist,
    onAddedToWatchlist: () => {
      const { listing, addAnalyticsLabel = 'add', source } = restOfProps;

      onAddedToWatchlist?.();
      trackExclusivesEvent('exclusives-favorite', addAnalyticsLabel, listing, {
        button_type: 'favorite',
        action_type: addAnalyticsLabel,
        source,
      });
    },
    isListingOnWatchlist: restOfProps.isListingOnWatchlist,
    source,
  });

  return (
    <WatchlistContext.Provider
      value={{
        ...isListingOnWatchlist,
        listing: restOfProps.listing,
        buttonDisplayText: 'Add to Watchlist',
        source,
      }}
    >
      <FavoriteButtonWithoutWatchlistContextProvider {...restOfProps} />
    </WatchlistContext.Provider>
  );
}

const disabledButtonStyle = {
  cursor: 'not-allowed',
  backgroundColor: 'var(--colors-neutrals10)',
  opacity: 1,
  border: 0,
};

export type FavoriteButtonWithoutWatchlistContextProvider = FavoriteButton;

export function FavoriteButtonWithoutWatchlistContextProvider({
  listing,
  isListingOnWatchlist,
  accountEmail,
  iconSize = 20,
  onRemovedFromWatchlist,
  addAnalyticsLabel = 'add',
  removeAnalyticsLabel = 'remove',
  analyticsAdditionalContextualData,
  children,
  buttonPropsOnWatchlist,
  ...props
}: FavoriteButtonWithoutWatchlistContextProvider) {
  const { trackExclusivesEvent } = useExclusiveAnalytics();
  const { toggleWatchlist, isLoading, isOnWatchlist, setIsOnWatchlist, source } =
    useWatchlistContext();
  const [isWatchlistModalOpen, toggleIsWatchlistModalOpen] = useReducer((s) => !s, false);

  return (
    <>
      <Button
        loadingText={''}
        variant="secondary"
        border="none"
        borderRadius="rounded"
        aria-label="Favorite Button"
        _disabled={disabledButtonStyle}
        analyticsName="cosmos-exclusives-favorite-button"
        height="36px"
        width="36px"
        minWidth="36px"
        minHeight="36px"
        padding="0"
        boxShadow="0px 5px 10px rgb(29 71 123 / 4%), 0px 1px 5px rgb(29 71 123 / 12%)"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          if (!accountEmail) {
            toggleIsWatchlistModalOpen();
          } else {
            toggleWatchlist(accountEmail).then(() => {
              trackExclusivesEvent(
                'exclusives-favorite',
                isOnWatchlist ? removeAnalyticsLabel : addAnalyticsLabel,
                listing,
                {
                  button_type: 'favorite',
                  action_type: isOnWatchlist ? removeAnalyticsLabel : addAnalyticsLabel,
                  source,
                  ...analyticsAdditionalContextualData,
                },
              );
            });
          }
        }}
        disabled={isLoading || isWatchlistModalOpen || (!accountEmail && isOnWatchlist)}
        loading={isLoading || isWatchlistModalOpen}
        {...props}
        {...(isOnWatchlist && buttonPropsOnWatchlist)}
      >
        <Flex
          /**
           * This is to ensure the selective spritesheet doesn't expand the button.
           */
          width="0px"
        >
          <SelectiveSpritesheet icons={[Favorite, FavoriteFilled]} />
        </Flex>
        <Icon
          color={isOnWatchlist ? 'brand50' : 'warmgrey900'}
          name={isOnWatchlist ? 'favorite-filled' : 'favorite'}
          // @ts-expect-error - This is temporary to support existing views that use this button. We should remove this once we've updated the existing views to Novos.
          size={iconSize}
        />
        {children?.({ isOnWatchlist: !!isOnWatchlist })}
      </Button>
      {!accountEmail && (
        <WatchlistModal
          listing={listing}
          isOpen={isWatchlistModalOpen}
          setIsOnWatchlist={setIsOnWatchlist}
          streetName={getListingStreet(listing)}
          onRequestClose={toggleIsWatchlistModalOpen}
          origin="exclusives-favorite"
          _analyticsLabel={addAnalyticsLabel}
          analyticsAdditionalContextualData={{
            button_type: 'favorite',
            action_type: isOnWatchlist ? removeAnalyticsLabel : addAnalyticsLabel,
            source,
            ...analyticsAdditionalContextualData,
          }}
          ignoreSellerOfferExperiment
          source={props.source !== 'saved-homes' ? props.source : undefined}
        />
      )}
    </>
  );
}
